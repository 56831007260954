import React from 'react'
import {
  BoundedContent,
  GridContainer,
  Row,
  Column,
  Link,
} from '@vp/swan'
import { TokenReplacer, LinkFactory } from '@vp/digital-token-replacer-lib-ubik'

import { trackFAQLinks } from '../WixDomainsPDPAnalytics'
import { getFree1YearDomainVoucherUrl } from '@vp/digital-url-service-lib-ubik'
import { FaqContainer } from '@vp/digital-faq-lib-ubik'
import { useLocale } from '@vp/digital-locale-lib-ubik'
import { PageContextProps } from '../../../search-and-suggestions/src/components/common/props.types'
import { getVistaWixLegacyCustomerHelpUrl } from '../../../common/helpers/wixDomainsUrls'

const FaqSection = ({ pageContext }: PageContextProps) => {
  const { languageCode } = useLocale()

  const { faqs } = pageContext.pageData
  const {
    locale,
    commonLocalizedUrls: { wixWebsitesAnonymousPlansUrlLocalized },
  } = pageContext

  const faqsData: any = {
    title: pageContext.pageData.faqs.title,
    features: pageContext.pageData.faqs.features
      .filter(
        (feature: any) =>
          !feature?.key ||
          (feature?.key === 'vistaWixLegacyFaqs' && locale === 'en-US')
      )
      .map((feature: any) => ({
        title: feature.title,
        description: (
          <TokenReplacer
            template={feature.description}
            replacements={{
              link: LinkFactory(
                {
                  domainsSearchBar: '#digital-search-component-section',
                },
                null,
                '_self'
              ),

              premiumWebsitePlan: (
                <>
                  <Link
                    href={wixWebsitesAnonymousPlansUrlLocalized.url}
                    onClick={() => {
                      trackFAQLinks(wixWebsitesAnonymousPlansUrlLocalized.url)
                    }}
                  >
                    {faqs.features[0].valueText}
                  </Link>
                </>
              ),

              vistaXWixPremiumPlan: (
                <>
                  <Link
                    href={wixWebsitesAnonymousPlansUrlLocalized.url}
                    onClick={() => {
                      trackFAQLinks(wixWebsitesAnonymousPlansUrlLocalized.url)
                    }}
                  >
                    {faqs.features[1].valueText}
                  </Link>
                </>
              ),

              yearlyPremiumWebsitePlan: (
                <>
                  <Link
                    href={wixWebsitesAnonymousPlansUrlLocalized.url}
                    onClick={() => {
                      trackFAQLinks(wixWebsitesAnonymousPlansUrlLocalized.url)
                    }}
                  >
                    {faqs.features[4].valueText}
                  </Link>
                </>
              ),

              firstYearSubscription: (
                <>
                  <Link
                    href={getFree1YearDomainVoucherUrl(languageCode)}
                    onClick={() => {
                      trackFAQLinks(getFree1YearDomainVoucherUrl(languageCode))
                    }}
                  >
                    {faqs.features[1].hoverText}
                  </Link>
                </>
              ),

              learnMore: (
                <>
                  <Link
                    href={getFree1YearDomainVoucherUrl(languageCode)}
                    onClick={() => {
                      trackFAQLinks(getFree1YearDomainVoucherUrl(languageCode))
                    }}
                  >
                    {faqs.features[4].hoverText}
                  </Link>
                </>
              ),

              vistaWixLegacyFaqsUrl: (
                <>
                  <Link
                    href={getVistaWixLegacyCustomerHelpUrl()}
                    onClick={() => {
                      trackFAQLinks(getVistaWixLegacyCustomerHelpUrl())
                    }}
                  >
                    {faqs.features[5].valueText}
                  </Link>
                </>
              ),
            }}
            convertMarkdown
          />
        ),
      })),
  }

  return (
    <>
      {faqsData && (
        <BoundedContent
          as='article'
          className='wix-domains-pdp__faq__container'
        >
          <GridContainer>
            <Row>
              <Column span={12}>
                <FaqContainer content={faqsData} titleSize='x3large' />
              </Column>
            </Row>
          </GridContainer>
        </BoundedContent>
      )}
    </>
  )
}

export default FaqSection
