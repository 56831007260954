import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { ProductApp } from '../web/product-page/ProductApp'
export const Fragment = (props: any) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  useSwanStyleKeys(['core'])

  return <ProductApp {...props} />
}
