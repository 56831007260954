const CLOUDINARY_IMAGE_HOST = 'cms.cloudinary.vpsvc.com'
export const BREAKPOINTS = [
  '380w',
  '512w',
  '768w',
  '1024w',
  '1280w',
  '1500w',
  '2400w',
  '3000w',
  '3780w',
]
export const BREAKPOINTS_SIZES = [
  '(max-width 499px) 380px',
  '(max-width 699px) 512px',
  '(max-width 999px) 768px',
  '1024w',
  '1280w',
  '1500w',
  '2400w',
  '3000w',
  '3780w',
].join(', ')

// TODO: move this function to a separate lib if it proves useful ;)
// This function should be used to fill the srcset attribute in the <img> tag
export default function getResponsiveCloudinaryImage (imageSrc: string): string {
  if (
    imageSrc === undefined ||
    imageSrc === null ||
    !imageSrc.includes(CLOUDINARY_IMAGE_HOST)
  ) {
    throw new Error(`Include a valid cloudinary Image url ${imageSrc}`)
  }
  return BREAKPOINTS.map(
    (breakpoint) =>
      `${imageSrc
        .replace(/(,)?(w_[0-9]+)(,|\/)?/, '')
        .replace(
          '/upload/',
          `/upload/w_${breakpoint.replace('w', '')}/`
        )} ${breakpoint}`
  ).join(',')
}

export function getBlurredCloudinaryImage (imageSrc: string) {
  if (
    imageSrc === undefined ||
    imageSrc === null ||
    !imageSrc.includes(CLOUDINARY_IMAGE_HOST)
  ) {
    throw new Error(`Include a valid cloudinary Image url ${imageSrc}`)
  }
  return imageSrc.replace('f_auto/q_auto', 'e_blur:1000,q_1,f_auto')
}
export function getWebpCloudinaryImage (imageSrc: string) {
  if (
    imageSrc === undefined ||
    imageSrc === null ||
    !imageSrc.includes(CLOUDINARY_IMAGE_HOST)
  ) {
    throw new Error(`Include a valid cloudinary Image url ${imageSrc}`)
  }
  return imageSrc.replace('f_auto', 'f_webp')
}
