// Analytics related to the Wix Domains product page
import {
  trackProductPageLoad,
  trackNavigationEvent,
  analyticsDataRegistry,
  makeEventDetail,
} from '@vp/digital-tracking-lib-ubik'

const NAVIGATION_CLICKED_ACTION = 'Navigation Clicked'
export const DOMAINS_MAIN_HERO_LABEL = 'Content:Main Hero'
export const DOMAINS_MERCHANDISING_SECTION_LABEL =
  'Content:Merchandising Section'
export const DOMAINS_MAIN_HERO_PAGE_ZONE = 'Main Hero'
export const DOMAINS_MERCHANDISING_SECTION_PAGE_ZONE = 'Merchandising Section'
export const GET_DOMAIN_CTA_DETAIL = 'Get a Domain'
export const CONNECT_A_DOMAIN_I_OWN_CTA_DETAIL =
  'Connect a Domain I already own'
export const GET_STARTED_CTA = 'Get started'
export const GET_STARTED_CTA_DETAIL = `${GET_STARTED_CTA}:1`
export const FIND_MY_DOMAIN_CTA = 'Find my domain'
export const FIND_MY_DOMAIN_CTA_DETAIL = `${FIND_MY_DOMAIN_CTA}:2`
export const DOMAIN_SEARCH_LABEL =
  NAVIGATION_CLICKED_ACTION + DOMAINS_MAIN_HERO_LABEL
export const DOMAIN_SEARCH_CTA_DETAIL = 'Search for a domain'

export const FAQ_LINKS_LABEL = 'Content:FAQ section'
export const FAQ_LINKS_PAGE_ZONE = 'FAQ section'

const { wixDomains, wixDomainsSearch, wixDomainsSearchResults, wixMySites } =
  analyticsDataRegistry

export function getTrackingAssetName (): string {
  const trackingAssetName = wixDomainsSearch.trackingAssetName

  return trackingAssetName
}

// Fires a product page load event, and a product viewed event
export function trackWixDomainsPDPPageLoad (): void {
  trackProductPageLoad(wixDomains)
}

// Fires a product page load event, and a product viewed event
export function trackWixDomainsPDPSearchPageLoad (): void {
  trackProductPageLoad(wixDomainsSearch)
}

export function trackWixGetStartedCTAClick (destinationUrl: string) {
  wixMySites.pageUri = destinationUrl.split('?')[0]
  // Navigates to wix my sites page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_MERCHANDISING_SECTION_LABEL,
    wixDomains, // source
    wixMySites, // target
    DOMAINS_MERCHANDISING_SECTION_PAGE_ZONE,
    GET_STARTED_CTA_DETAIL,
    {
      eventDetail: makeEventDetail(
        wixDomains.pageUri,
        wixMySites.pageUri,
        DOMAINS_MERCHANDISING_SECTION_PAGE_ZONE,
        GET_STARTED_CTA
      ),
    }
  )
}

export function trackWixFindMyDomainCTAClick (destinationUrl: string) {
  wixDomainsSearchResults.pageUri = destinationUrl.split('?')[0]
  // Navigates to wix domain search page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_MERCHANDISING_SECTION_LABEL,
    wixDomainsSearch, // source
    wixDomainsSearchResults, // target
    DOMAINS_MERCHANDISING_SECTION_PAGE_ZONE,
    FIND_MY_DOMAIN_CTA_DETAIL,
    {
      eventDetail: makeEventDetail(
        wixDomains.pageUri,
        wixDomainsSearchResults.pageUri,
        DOMAINS_MERCHANDISING_SECTION_PAGE_ZONE,
        FIND_MY_DOMAIN_CTA
      ),
    }
  )
}

export function trackWixConnectDomainsCTAClick (destinationUrl: string) {
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  // Navigates to wix domains page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_MAIN_HERO_LABEL,
    wixDomainsSearch, // source
    wixDestinationDomains, // target
    DOMAINS_MAIN_HERO_PAGE_ZONE,
    CONNECT_A_DOMAIN_I_OWN_CTA_DETAIL
  )
}

export const trackFAQLinks = (destination: string) => {
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    FAQ_LINKS_LABEL,
    wixDomainsSearch, // source
    {
      pageUri: destination,
    }, // target
    FAQ_LINKS_PAGE_ZONE
  )
}
