import React from 'react'

import { FeaturesContainer } from '@vp/digital-features-lib-ubik'
import { LinkFactory, TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'
import {
  BasicResponsiveImage,
  BoundedContent,
  Column,
  FlexBox,
  GridContainer,
  H2,
  Hidden,
  ProgressiveImage,
  Row,
  Typography,
} from '@vp/swan'
import { PageContextProps } from '../../../search-and-suggestions/src/components/common/props.types'
import { getBlurredCloudinaryImage } from '../../../common/helpers/cloudinaryImageResizerHelper'

const QuestionsSection = ({ pageContext }: PageContextProps) => {
  const { domainNameImportance, whyThroughVista, whyFeatures } =
    pageContext.pageData
  const {
    commonLocalizedUrls: { wixWebsitesAnonymousPlansUrlLocalized },
  } = pageContext

  return (
    <>
      {/* Wix Domains PDP  What is a domain name and why is it important? */}
      {domainNameImportance && (
        <Typography component='article' mt={{ lg: 8 }}>
          <BoundedContent>
            <GridContainer>
              <Row>
                <Column offset={0} span={12} spanMd={7} spanLg={7} spanXl={7}>
                  <H2 fontSize='x2large' fontWeight='bold' mt={7}>
                    {domainNameImportance.title}
                  </H2>
                  <Typography component='p' mt='6'>
                    {domainNameImportance.description}
                  </Typography>
                  <H2 fontSize='x2large' fontWeight='bold' mt={7}>
                    {whyThroughVista.title}
                  </H2>
                  <Typography component='p' mt='6'>
                    {whyThroughVista.description}
                  </Typography>
                  <Typography component='p' mt='7' fontWeight='bold'>
                    <TokenReplacer
                      template={whyThroughVista.features[0].title}
                      replacements={{
                        link: LinkFactory({
                          anonymousPlanPickerUrl:
                            wixWebsitesAnonymousPlansUrlLocalized.url,
                        }),
                      }}
                      convertMarkdown
                    />
                  </Typography>
                </Column>
                <Column span={12} spanMd={5} spanLg={5} spanXl={5}>
                  <Hidden sm xs style={{ height: '100%' }}>
                    <FlexBox
                      alignItems='center'
                      flexWrap='wrap'
                      style={{ height: '100%' }}
                    >
                      <ProgressiveImage
                        style={{ width: '100%' }}
                        placeholder={getBlurredCloudinaryImage(
                          domainNameImportance.media[0].mediaUrl
                        )}
                        src={domainNameImportance.media[0].mediaUrl}
                      >
                        {(image: { src: any }, isLoading: any) => (
                          <BasicResponsiveImage
                            src={image.src}
                            aspectRatio={0.659}
                            alt={domainNameImportance.media[0].mediaUrl}
                          />
                        )}
                      </ProgressiveImage>
                    </FlexBox>
                  </Hidden>
                </Column>
              </Row>
            </GridContainer>
          </BoundedContent>
        </Typography>
      )}
      {/* Wix Domains PDP  Why get your domain */}
      {whyThroughVista && (
        <Typography
          component='article'
          className='wix-domains-pdp__domains-why-vista__container'
        >
          <BoundedContent>
            <FeaturesContainer
              className='wix-domains-pdp__domains-why-vista__feature'
              content={whyFeatures}
              defaultNumberOfColumns={3}
              nameSize='large'
              descriptionSize='small'
              titleSize='x2large'
              featuresColumnSpanSizes={{
                spanLg: 4,
                spanMd: 4,
                spanSm: 4,
                spanXs: 12,
              }}
            />
          </BoundedContent>
        </Typography>
      )}
    </>
  )
}

export default QuestionsSection
