import '@vp/digital-faq-lib-ubik/dist/index.css'
import '@vp/digital-features-lib-ubik/dist/index.css'
import styles, { stylesheet } from './wixDomainsPdp.scss'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Banner,
  BannerDescription,
  BannerEyebrow,
  BannerImage,
  BannerImageContainer,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  BoundedContent,
  Box,
  Button,
  Column,
  FluidImage,
  GridContainer,
  H2,
  Link,
  P,
  ResponsiveImage,
  Row,
  SkeletonText,
  SkeletonTextLine,
  Spinner,
  Typography,
  useScreenClass,
} from '@vp/swan'
import { SEOContainer } from '@vp/digital-seo-lib-ubik'
import { isProduction } from '@vp/digital-environment-lib-ubik'
import {
  DOMAINS_MERCHANDISING_SECTION_LABEL,
  FIND_MY_DOMAIN_CTA_DETAIL,
  GET_STARTED_CTA_DETAIL,
  trackWixConnectDomainsCTAClick,
  trackWixFindMyDomainCTAClick,
  trackWixGetStartedCTAClick,
} from './WixDomainsPDPAnalytics'
import {
  SearchComponent,
  SearchComponentCTAModel,
  SearchComponentInputModel,
  useHasOwnDomainValue,
  useSearchedDomainValue,
} from '@vp/digital-search-component-lib-ubik'

import { getWixPrivacyPolicyUrl, getWixTermsOfUseUrl, } from '@vp/digital-url-service-lib-ubik'
import { CANONICAL_ID, } from '@vp/digital-auth-lib-ubik'
import { getPartnerAccountProducts, PartnerAccountProducts, } from '@vp/digital-paid-lib-ubik'
import { useLocale } from '@vp/digital-locale-lib-ubik'

import { LinkFactory, TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'
import FaqSection from './components/FaqSection'
import QuestionsSection from './components/QuestionsSection'
import { WIX_DOMAINS_DYNAMIC_UI_ROLLOUT_LOCALES } from '../../common/constants/experimentLocale'
import { urlParamsEnum } from '../../common/enum/urlParamsEnum'
import { sourceEnum } from '../../common/enum/sourceEnum'
import { getWixPartnerConnectDomainBuildWebsiteUrl } from '../../common/helpers/wixDomainsUrls'
import Redirect from '../../common/Redirect'
import getResponsiveCloudinaryImage, {
  BREAKPOINTS_SIZES,
} from '../../common/helpers/cloudinaryImageResizerHelper'
import { useIdentity, useLogger, useStyles } from '@vp/ubik-context'
import { VPAuthIdentity } from '../../common/types/Types'
import { useSignedInAction } from '../../common/helpers/useSignedInAction'

const WixDomainsPdp = (pageContext : any) => {
  useStyles(stylesheet)
  const { hero, createWebsiteSubHero, customersChooseWixSubHero, seo } =
    pageContext.pageData
  const {
    urlWixPartnerGetStartedTnCAcceptedlUrlsPdp,
    locale,
    wixDomainsSearchAndSuggestionsUrlLocalized,
    commonLocalizedUrls: { wixPlusLink },
  } = pageContext

  const { languageCode } = useLocale()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [redirectPath, setRedirectPath] = useState(null)
  const [domain, setDomain, resetDomain] = useSearchedDomainValue()
  const [, setHasOwnDomainValue, resetHasOwnDomainValue] =
    useHasOwnDomainValue()
  const [connectDomainsLinkUrl, setConnectDomainsLinkUrl] =
    useState<string>(null)
  const logger = useLogger()
  const { auth, isSignedIn } = useIdentity()
  const canonicalId = auth?.getCanonicalId()
  const [isPremium, setIsPremium] = useState(false)
  const [numberOfSites, setNumberOfSites] = useState(null)
  const [displaySkeleton, setDisplaySkeleton] = useState(true)

  useEffect(() => {
    async function fetchData () {
      try {
        const data = await getPartnerAccountProducts(canonicalId)
        setNumberOfSites(data?.wixSites?.length)
        setIsPremium(data.wixSites.some((site: any) => site.premium))
      } catch (error) {
        setNumberOfSites(0)
        logger.warn('Error while loading getPartnerAccountProducts' + error)
      } finally {
        setDisplaySkeleton(false)
      }
    }
    if (!canonicalId) {
      return
    }
    if (!isSignedIn) {
      setNumberOfSites(0)
      setDisplaySkeleton(false)
      return
    }
    fetchData()
  }, [canonicalId, logger, isSignedIn])

  const createYourOwnCustomSiteData = () => {
    let title: string,
      descriptionLong: string,
      ctaName: string,
      description: string
    let backgroundImage = createWebsiteSubHero.icons[0].secureUrl
    let isWixPlusLink = false
    if (
      numberOfSites === 0 ||
      WIX_DOMAINS_DYNAMIC_UI_ROLLOUT_LOCALES.indexOf(locale) !== 0
    ) {
      title = createWebsiteSubHero?.title
      descriptionLong = createWebsiteSubHero?.descriptionLong
      ctaName = createWebsiteSubHero?.ctaName
      description = createWebsiteSubHero.heroMetadata[0].description
    } else {
      if (isPremium) {
        title = createWebsiteSubHero.heroMetadata[1].features[0].title
        descriptionLong =
          createWebsiteSubHero.heroMetadata[1].features[0].description
        ctaName = createWebsiteSubHero.heroMetadata[1].features[0].altText
        isWixPlusLink = true
        backgroundImage = createWebsiteSubHero.icons[1].secureUrl
      } else {
        title = createWebsiteSubHero.heroMetadata[1].features[1].title
        descriptionLong =
          createWebsiteSubHero.heroMetadata[1].features[1].description
        ctaName = createWebsiteSubHero.heroMetadata[1].features[1].altText
      }
    }
    return (
      <Column
        span={12}
        spanMd={6}
        spanLg={6}
        spanXl={6}
        className={styles.wixDomainsPdpCustomersContainer}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: '#f3e8c1',
        }}
      >
        <Box>
          <H2 fontSize='x2large' mb={0}>
            {title}
          </H2>

          <Typography m={0} mt='6' component='p' fontSize='small'>
            {descriptionLong}
          </Typography>

          <Box mt='6'>
            {displayGetStartedButtons(ctaName, description, isWixPlusLink)[0]}
          </Box>
        </Box>
      </Column>
    )
  }

  let querySiteId: string = null
  if (typeof window !== 'undefined' && window.location?.search) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    querySiteId =
      urlParams?.get(urlParamsEnum.SITE_ID)?.toLowerCase() ??
      urlParams?.get(urlParamsEnum.UPGRADE_SITE_ID)?.toLowerCase()
  }

  const source = sourceEnum.DOMAINS_PDP

  const urlWixPartnerGetStartedTnCAcceptedlUrl = isProduction()
    ? urlWixPartnerGetStartedTnCAcceptedlUrlsPdp.prod
    : urlWixPartnerGetStartedTnCAcceptedlUrlsPdp.qa

  const handleSearchCTA = useCallback(
    (domain: any) => {
      setDomain(domain)
      handleWixFindMyDomainNavigation(false)
    },
    [handleWixFindMyDomainNavigation]
  )

  const ctaProps: SearchComponentCTAModel = {
    onClickHandler: handleSearchCTA,
  }

  const inputProps: SearchComponentInputModel = {
    textInputPlaceholder: hero.secondaryCtaDescription,
    value: domain,
    setValue: setDomain,
  }

  const fetchSiteList = async (locale: string, identity:VPAuthIdentity) => {
    const data = await getPartnerAccountProducts(
      identity.profile[CANONICAL_ID] as string
    )
    const redirectUrl = getConnectDomainUrl(data)
    setConnectDomainsLinkUrl(redirectUrl)
    trackWixConnectDomainsCTAClick(redirectUrl)
  }

  const [, , initiateFetchSiteList, isFetchingSiteList] = useSignedInAction<
    string,
    void
  >(fetchSiteList, 'fetch-site-list-after-sign-in')

  function getConnectDomainUrl (productDetails: PartnerAccountProducts): string {
    const numberOfSites = productDetails?.wixSites?.length
    let siteId: string
    if (numberOfSites === 1) {
      siteId = productDetails?.wixSites[0].id
    }
    return getWixPartnerConnectDomainBuildWebsiteUrl(
      locale,
      source,
      domain,
      source,
      siteId
    )
  }

  const isMobile = useScreenClass() === 'xs'

  async function handleGetStartedNavigation (wixPlusUrl?: boolean) {
    logger.debug(
      'Clicking Domains PDP Get Started to Wix IntroFunnel Navigate'
    )
    let url = urlWixPartnerGetStartedTnCAcceptedlUrl
    if (querySiteId) {
      url += `?${urlParamsEnum.SITE_ID}=${querySiteId}`
    }
    if (isMobile) {
      url += `&${urlParamsEnum.DEVICE_TYPE}=mobile`
    }
    if (wixPlusUrl) {
      url = wixPlusLink.url
    }
    trackWixGetStartedCTAClick(url)
    setIsRedirecting(true)
    setRedirectPath(url)
  }

  function handleWixConnectDomainsNavigation () {
    logger.debug('Clicking Domains PDP Connect Domains link')
    setHasOwnDomainValue(true)
    initiateFetchSiteList(locale)
  }

  function handleWixFindMyDomainNavigation (navigateWithouData = false) {
    if (navigateWithouData) {
      resetDomain()
      resetHasOwnDomainValue()
    }
    setHasOwnDomainValue(false)
    logger.debug('Clicking Wix Domains PDP Find My domain link')
    let url = wixDomainsSearchAndSuggestionsUrlLocalized.url
    url += `?${urlParamsEnum.REQUESTOR}=` + sourceEnum.DOMAINS_PDP
    if (querySiteId) {
      url += `&${urlParamsEnum.UPGRADE_SITE_ID}=${querySiteId}`
    }
    trackWixFindMyDomainCTAClick(url)
    setIsRedirecting(true)
    setRedirectPath(url)
  }

  const CONNECT_DOMAINS_KEY = 'wixConnectDomainsLink'
  const connectDomainsLink = hero.heroMetadata?.find(
    ({ key }: { key: string }) => key === CONNECT_DOMAINS_KEY
  )

  const displayGetStartedButtons = (
    ctaName: string,
    footer: string,
    wixPlusUrl?: boolean
  ) => {
    const footerJSX = (
      <P fontSize='xsmall' mt='6' mb={0}>
        <TokenReplacer
          template={footer}
          replacements={{
            link: LinkFactory({
              wixTermsOfUseUrl: getWixTermsOfUseUrl(languageCode),
              wixPrivacyPolicyUrl: getWixPrivacyPolicyUrl(languageCode),
            }),
          }}
          convertMarkdown
        />
      </P>
    )
    return [
      // First sub hero CTA
      <React.Fragment key='first-cta'>
        <Button
          skin='secondary'
          onClick={() => handleGetStartedNavigation(wixPlusUrl)}
          data-section={DOMAINS_MERCHANDISING_SECTION_LABEL}
          data-position='2'
          data-translation={GET_STARTED_CTA_DETAIL}
        >
          {ctaName}
        </Button>
        {footerJSX}
      </React.Fragment>,

      // Second sub hero CTA
      <React.Fragment key='second-cta'>
        <Button
          skin='secondary'
          onClick={() => handleWixFindMyDomainNavigation(true)}
          data-section={DOMAINS_MERCHANDISING_SECTION_LABEL}
          data-position='3'
          data-translation={FIND_MY_DOMAIN_CTA_DETAIL}
        >
          {ctaName}
        </Button>
      </React.Fragment>,
    ]
  }

  return (
    <Redirect
      redirectPath={redirectPath || connectDomainsLinkUrl}
      isRedirecting={isRedirecting || isFetchingSiteList}
    >
      <>
        <SEOContainer content={seo} />

        {/* Wix Domains PDP hero */}
        {hero && (
          <Banner bgc='info' darkMode id='digital-search-component-section'>
            <BannerTextContainer>
              <BannerText pb={0}>
                <BannerEyebrow>
                  <ResponsiveImage
                    style={{ maxWidth: '10em' }}
                    src={hero.backgroundImage.media.secureUrl}
                    width='180'
                    height='31'
                    alt={hero.backgroundImage.media.fileName}
                  />
                </BannerEyebrow>
                <BannerTitle as='h1' fontSkin='title-display'>
                  {hero.title}
                </BannerTitle>
                <BannerDescription className={styles.wixDomainsPdpHeroDescription}>
                  <Typography mt='6' mb='6' component='p'>
                    {hero.descriptionLong}
                  </Typography>
                  <SearchComponent
                    ctaProps={ctaProps}
                    inputProps={inputProps}
                  />
                  <P mb={{ xs: 0, sm: 5 }}>
                    {connectDomainsLink.title}
                    <Link
                      onClick={() => {
                        handleWixConnectDomainsNavigation()
                      }}
                      paddingLeft='3'
                    >
                      {connectDomainsLink.description}
                    </Link>
                  </P>
                </BannerDescription>
              </BannerText>
            </BannerTextContainer>
            <BannerImageContainer>
              <BannerImage imageFocalPoint='center center'>
                <FluidImage
                  srcSet={getResponsiveCloudinaryImage(
                    hero.mediaList[1].media.secureUrl
                  )}
                  sizes={BREAKPOINTS_SIZES}
                  alt={hero.mediaList[1].altText}
                  style={{ objectFit: 'contain' }}
                />
              </BannerImage>
            </BannerImageContainer>
          </Banner>
        )}

        <QuestionsSection pageContext={pageContext} />

        {/* Wix PDP Create your own Site sub hero AND Wix Domains PDP Customers Choose Wix subhero */}
        <BoundedContent
          component='article'
          className={styles.wixDomainsPdpSubHeroContainer}
          mt={{ xs: 7, md: 9 }}
          mb={0}
          px={0}
        >
          <GridContainer>
            <Row>
              <>
                {displaySkeleton && (
                  <Column
                    span={12}
                    spanMd={6}
                    spanLg={6}
                    spanXl={6}
                    className={styles.wixDomainsPdpCustomersContainer}
                    style={{
                      backgroundImage: `url(${createWebsiteSubHero.icons[0].secureUrl})`,
                      backgroundColor: '#f3e8c1',
                    }}
                  >
                    <Box>
                      <SkeletonText>
                        <SkeletonTextLine fontSize='x2large' />
                        <SkeletonTextLine fontSize='x2large' />
                      </SkeletonText>
                      <SkeletonText mt='6'>
                        <SkeletonTextLine fontSize='standard' />
                        <SkeletonTextLine
                          fontSize='standard'
                          width='narrow'
                        />
                        <SkeletonTextLine fontSize='standard' />
                      </SkeletonText>
                      <Spinner mt={6} accessibleText='Loading' />
                      <SkeletonText mt='6'>
                        <SkeletonTextLine fontSize='small' />
                        <SkeletonTextLine
                          fontSize='small'
                          width='narrow'
                        />
                        <SkeletonTextLine fontSize='small' />
                        <SkeletonTextLine fontSize='small' />
                        <SkeletonTextLine fontSize='small' />
                      </SkeletonText>
                    </Box>
                  </Column>
                )}
                {!displaySkeleton && createYourOwnCustomSiteData()}
                {[customersChooseWixSubHero].map((subHero, index) => (
                  <Column
                    key={index}
                    span={12}
                    spanMd={6}
                    spanLg={6}
                    spanXl={6}
                    className={styles.wixDomainsPdpCustomersContainer}
                    style={{
                      backgroundImage: `url(${subHero.icons[0].secureUrl})`,
                    }}
                  >
                    <Box>
                      <H2 fontSize='x2large' mb={0}>
                        {subHero.title}
                      </H2>

                      <Typography m={0} mt='6' component='p' fontSize='small'>
                        {subHero.descriptionLong}
                      </Typography>

                      <Box mt='6'>
                        {
                          displayGetStartedButtons(
                            subHero.ctaName,
                            subHero.heroMetadata[0].description
                          )[index]
                        }
                      </Box>
                    </Box>
                  </Column>
                ))}
              </>
            </Row>
          </GridContainer>
        </BoundedContent>

        <FaqSection pageContext={pageContext} />
      </>
    </Redirect>
  )
}

export default React.memo(WixDomainsPdp)
