import React from 'react'
import { LocaleProvider } from '@vp/digital-locale-lib-ubik'
import {
  getClientEnvironment,
  RuntimeContextProvider,
} from '@vp/digital-environment-lib-ubik'
import { IdentityProvider, useSwanStyleKeys, VPAuthConfig } from '@vp/ubik-context'

import { PricingContentProvider, PricingContextProvider } from '@vp/digital-pricing-lib-ubik'
import WixDomainsPdp from './src/WixDomainsPdp'
export function ProductApp (props: any) {
  useSwanStyleKeys(['core', 'button', 'banner', 'progressiveImage', 'spinner', 'grid'])

  const createAuthConfig = (
    culture: string,
    isDevelopment: boolean
  ): VPAuthConfig => ({
    culture,
    developmentMode: isDevelopment,
    options: {
      requireSession: true,
      customText: 'Sign in to see your account',
    },
  })

  return (
    <LocaleProvider rawLocaleIdentifier={props.locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <IdentityProvider auth={createAuthConfig(props.locale, false)}>
          <PricingContextProvider>
            <PricingContentProvider pricingContent={props.billingPeriods}>
              <WixDomainsPdp {...props} />
            </PricingContentProvider>
          </PricingContextProvider>
        </IdentityProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  )
}
